import React, { useEffect, useState } from 'react'
import { Button, Checkbox, Tabs } from 'antd'
import './style.less'
import { DownOutlined, UpOutlined } from '@ant-design/icons'
import { httpGet } from '../../services/http'
import LogRocket from 'logrocket'
import { getItem, removeItem, setItem, tokenPaths } from '../../utils/localStorage'

const { TabPane } = Tabs

export function getCookieConsent() {
    const doesUserConsent = getItem(tokenPaths.consentCookies)
    if (!doesUserConsent) return false
    if (doesUserConsent?.toString() === 'true') {
        // legacy consent
        removeItem(tokenPaths.consentCookies)
    }
    const parsedConsent = JSON.parse(doesUserConsent)
    if (parsedConsent) return parsedConsent
    return false
}

export function hasCookieConsentStatistic() {
    const userConsent = getCookieConsent()
    return userConsent && userConsent?.Statistics
}

export function withdrawConsent() {
    removeItem(tokenPaths.consentCookies)
}

const options = [
    { label: 'Necessary', value: 'Necessary', disabled: true },
    { label: 'Statistics', value: 'Statistics' },
]

export default function CookieModal({ checkCookies }) {
    const [hasConsent, setHasConsent] = useState<boolean>(true)
    const [selection, setSelection] = useState<string[]>(['Necessary'])
    const [showMore, setShowMore] = useState<boolean>(false)

    useEffect(() => {
        const doesUserConsent = getItem(tokenPaths.consentCookies)
        if (!doesUserConsent) {
            setHasConsent(false)
        } else if (doesUserConsent.toString() === 'true') {
            // legacy consent
            removeItem(tokenPaths.consentCookies)
            setHasConsent(false)
        }
    }, [checkCookies])

    function updateConsent(checkedValues: string[]) {
        let newConsent = {
            Statistics: false,
        }
        if (checkedValues.includes('Statistics')) {
            newConsent['Statistics'] = true
        }
        setItem(tokenPaths.consentCookies, JSON.stringify(newConsent))
        httpGet(`/landing/status?new-cookie-consent=${JSON.stringify(newConsent)}`)
        setHasConsent(true)
    }

    function onChange(checkedValues) {
        setSelection(checkedValues)
    }

    if (hasConsent) return <></>

    return (
        <div className="cookie-affix">
            <div className="cookie-container">
                <h3>This website uses cookies</h3>
                <p className="cookie-text">
                    We use cookies to personalize content, analyse our traffic and to allow for
                    payments according to our pricing structure.
                </p>
                <div className="cookie-btns-container">
                    <Button
                        onClick={() => {
                            updateConsent([])
                        }}
                        size="small"
                        className="cookie-select-btn"
                    >
                        Use only necessary cookies
                    </Button>
                    <Button
                        onClick={() => {
                            updateConsent(selection)
                        }}
                        size="small"
                        className="cookie-select-btn-allow-more"
                        type="primary"
                    >
                        Allow selection
                    </Button>
                    <Button
                        onClick={() => {
                            updateConsent(['Statistics'])
                        }}
                        size="small"
                        className="cookie-select-btn-allow-more"
                        type="primary"
                    >
                        Allow all cookies
                    </Button>
                </div>
                <div className="cookie-selection-container">
                    <Checkbox.Group options={options} value={selection} onChange={onChange} />
                    <div
                        onClick={() => {
                            setShowMore(!showMore)
                        }}
                    >
                        Show details {showMore ? <UpOutlined /> : <DownOutlined />}
                    </div>
                </div>
                {showMore && (
                    <div className="cookie-more-container">
                        <Tabs
                            // type="card"
                            defaultActiveKey="1"
                            //onChange={callback}
                        >
                            <TabPane tab="Cookie declaration" key="1">
                                <div className="cookie-scrollable-container">
                                    <h4>Necessary:</h4>
                                    <p>
                                        Necessary cookies help make a website usable by enabling
                                        basic functions like page navigation and access to secure
                                        areas of the website. The website cannot function properly
                                        without these cookies.
                                        <br />
                                        ll33a.dk only uses cookies to authenticate users and to
                                        store their cookie consent. The following cookies are used:
                                        <br />
                                        authTokenRevisor, authToken, consentCookies.
                                        <br />
                                        We use Stripe for payments and Stripe uses several cookies
                                        to ensure secure online payments. See{' '}
                                        <a
                                            target="_blank"
                                            rel="noreferrer"
                                            href="https://stripe.com/cookies-policy/legal"
                                        >
                                            stripe.com/cookies-policy/legal
                                        </a>{' '}
                                        for more information.
                                    </p>
                                    <h4>Statistics:</h4>
                                    <p>
                                        Statistic cookies help website owners to understand how
                                        visitors interact with websites by collecting and reporting
                                        information. For this purpose ll33a.dk uses Logrocket. For
                                        more information see{' '}
                                        <a
                                            target="_blank"
                                            rel="noreferrer"
                                            href="https://docs.logrocket.com/docs/security"
                                        >
                                            docs.logrocket.com/docs/security
                                        </a>
                                        .
                                    </p>
                                </div>
                            </TabPane>
                            <TabPane tab="About cookies" key="2">
                                <div className="cookie-scrollable-container">
                                    <p>
                                        Cookies are small text files that can be used by websites to
                                        make a user's experience more efficient.
                                        <br />
                                        The law states that we can store cookies on your device if
                                        they are strictly necessary for the operation of this site.
                                        For all other types of cookies we need your permission.
                                        <br />
                                        This site uses different types of cookies. Some cookies are
                                        placed by third party services that appear on our pages.
                                        <br />
                                        You can at any time change or withdraw your consent from the
                                        Cookie Declaration on our website.
                                        <br />
                                        Learn more about who we are, how you can contact us and how
                                        we process personal data in our Privacy Policy.
                                        <br />
                                        Your consent applies to the following domains: ll33a.dk
                                    </p>
                                </div>
                            </TabPane>
                        </Tabs>
                    </div>
                )}
            </div>
        </div>
    )
}
